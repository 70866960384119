import React, { ReactElement } from "react";

export default function Logo(): ReactElement {
    return (
        <svg
            width="624"
            height="57"
            viewBox="0 0 624 57"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M83.9 47.7H70.6V8.39999H80.9C84.2 8.39999 86.7 8.79999 88.5 9.69999C90.3 10.6 91.7 11.8 92.7 13.3C93.7 14.8 94.1 16.6 94.1 18.6C94.1 22.5 92.1 25.2 88.1 26.9C91 27.4 93.3 28.6 95 30.5C96.7 32.3 97.6 34.5 97.6 37C97.6 39 97.1 40.8 96 42.4C95 44 93.4 45.3 91.4 46.3C89.4 47.2 86.9 47.7 83.9 47.7ZM81.1 13.4H76.3V25.2H80C83 25.2 85.1 24.6 86.4 23.5C87.7 22.4 88.3 20.9 88.3 19C88.3 15.3 85.9 13.4 81.1 13.4ZM81.5 30.2H76.2V42.7H81.8C84.9 42.7 87 42.5 88.1 42C89.2 41.5 90.1 40.8 90.7 39.8C91.4 38.8 91.7 37.7 91.7 36.5C91.7 35.3 91.3 34.2 90.6 33.2C89.9 32.2 88.9 31.5 87.6 31C86.3 30.5 84.3 30.2 81.5 30.2Z"
                fill="#008A7B"
            />
            <path
                d="M118.9 47.7V44.4C117.8 45.6 116.6 46.5 115.2 47.2C113.8 47.9 112.4 48.2 111 48.2C109.4 48.2 107.9 47.8 106.5 47C105.1 46.2 104.1 45.1 103.4 43.7C102.7 42.3 102.3 40 102.3 36.7V21.8H107.4V36.6C107.4 39.3 107.8 41.2 108.6 42.3C109.4 43.4 110.7 43.9 112.7 43.9C115.1 43.9 117.2 42.7 118.9 40.3V21.8H124V47.7H118.9V47.7Z"
                fill="#008A7B"
            />
            <path
                d="M130 45.9V40.4C131.4 41.4 132.9 42.2 134.4 42.9C135.9 43.5 137.2 43.8 138.2 43.8C139.3 43.8 140.2 43.5 141 43C141.8 42.5 142.2 41.8 142.2 41.1C142.2 40.3 142 39.7 141.5 39.2C141 38.7 139.9 38 138.3 37C135 35.2 132.8 33.6 131.8 32.3C130.8 31 130.3 29.6 130.3 28C130.3 26 131.1 24.4 132.6 23.1C134.2 21.8 136.1 21.2 138.6 21.2C141.1 21.2 143.7 21.9 146.4 23.3V28.4C143.4 26.6 140.9 25.6 138.9 25.6C137.9 25.6 137.1 25.8 136.5 26.2C135.9 26.6 135.6 27.2 135.6 27.9C135.6 28.5 135.9 29.1 136.4 29.6C137 30.1 137.9 30.8 139.3 31.6L141.1 32.6C145.4 35 147.6 37.7 147.6 40.7C147.6 42.8 146.8 44.6 145.1 45.9C143.4 47.3 141.3 47.9 138.7 47.9C137.2 47.9 135.8 47.7 134.6 47.4C133.3 47.4 131.8 46.8 130 45.9Z"
                fill="#008A7B"
            />
            <path
                d="M156.1 10.9C156.9 10.9 157.7 11.2 158.3 11.8C158.9 12.4 159.2 13.1 159.2 13.9C159.2 14.7 158.9 15.4 158.3 16C157.7 16.6 157 16.9 156.1 16.9C155.3 16.9 154.6 16.6 154 16C153.4 15.4 153.1 14.7 153.1 13.9C153.1 13.1 153.4 12.4 154 11.8C154.6 11.2 155.3 10.9 156.1 10.9ZM153.6 21.8H158.7V47.7H153.6V21.8Z"
                fill="#008A7B"
            />
            <path
                d="M171.2 21.8V25.1C173.5 22.6 176.1 21.3 179 21.3C180.6 21.3 182.1 21.7 183.5 22.6C184.9 23.4 185.9 24.6 186.7 26C187.4 27.5 187.8 29.8 187.8 32.9V47.7H182.7V33C182.7 30.4 182.3 28.5 181.5 27.3C180.7 26.2 179.3 25.6 177.4 25.6C175 25.6 172.9 26.8 171.2 29.2V47.7H166V21.8H171.2Z"
                fill="#008A7B"
            />
            <path
                d="M217.1 35.2H198.9C199 37.7 199.9 39.7 201.4 41.1C202.9 42.6 204.9 43.3 207.3 43.3C210.7 43.3 213.8 42.2 216.7 40.1V45.1C215.1 46.2 213.6 46.9 212 47.4C210.4 47.9 208.6 48.1 206.5 48.1C203.6 48.1 201.3 47.5 199.6 46.3C197.8 45.1 196.4 43.5 195.4 41.5C194.3 39.5 193.8 37.2 193.8 34.6C193.8 30.7 194.9 27.4 197.2 25C199.4 22.5 202.3 21.3 205.9 21.3C209.3 21.3 212.1 22.5 214.1 24.9C216.1 27.3 217.2 30.5 217.2 34.6V35.2H217.1ZM198.9 32.2H212C211.9 30.2 211.3 28.6 210.2 27.5C209.1 26.4 207.6 25.8 205.8 25.8C204 25.8 202.5 26.4 201.3 27.5C200.1 28.5 199.3 30.1 198.9 32.2Z"
                fill="#008A7B"
            />
            <path
                d="M221.3 45.9V40.4C222.7 41.4 224.2 42.2 225.7 42.9C227.2 43.5 228.5 43.8 229.5 43.8C230.6 43.8 231.5 43.5 232.3 43C233.1 42.5 233.5 41.8 233.5 41.1C233.5 40.3 233.3 39.7 232.8 39.2C232.3 38.7 231.2 38 229.6 37C226.3 35.2 224.1 33.6 223.1 32.3C222.1 31 221.6 29.6 221.6 28C221.6 26 222.4 24.4 223.9 23.1C225.5 21.8 227.4 21.2 229.9 21.2C232.4 21.2 235 21.9 237.7 23.3V28.4C234.7 26.6 232.2 25.6 230.2 25.6C229.2 25.6 228.4 25.8 227.8 26.2C227.2 26.6 226.9 27.2 226.9 27.9C226.9 28.5 227.2 29.1 227.7 29.6C228.3 30.1 229.2 30.8 230.6 31.6L232.4 32.6C236.7 35 238.9 37.7 238.9 40.7C238.9 42.8 238.1 44.6 236.4 45.9C234.7 47.3 232.6 47.9 230 47.9C228.5 47.9 227.1 47.7 225.9 47.4C224.7 47.4 223.2 46.8 221.3 45.9Z"
                fill="#008A7B"
            />
            <path
                d="M243.6 45.9V40.4C245 41.4 246.5 42.2 248 42.9C249.5 43.5 250.8 43.8 251.8 43.8C252.9 43.8 253.8 43.5 254.6 43C255.4 42.5 255.8 41.8 255.8 41.1C255.8 40.3 255.6 39.7 255.1 39.2C254.6 38.7 253.5 38 251.9 37C248.6 35.2 246.4 33.6 245.4 32.3C244.4 31 243.9 29.6 243.9 28C243.9 26 244.7 24.4 246.2 23.1C247.8 21.8 249.7 21.2 252.2 21.2C254.7 21.2 257.3 21.9 260 23.3V28.4C257 26.6 254.5 25.6 252.5 25.6C251.5 25.6 250.7 25.8 250.1 26.2C249.5 26.6 249.2 27.2 249.2 27.9C249.2 28.5 249.5 29.1 250 29.6C250.6 30.1 251.5 30.8 252.9 31.6L254.7 32.6C259 35 261.2 37.7 261.2 40.7C261.2 42.8 260.4 44.6 258.7 45.9C257 47.3 254.9 47.9 252.3 47.9C250.8 47.9 249.4 47.7 248.2 47.4C246.9 47.4 245.4 46.8 243.6 45.9Z"
                fill="#008A7B"
            />
            <path
                d="M267.4 47.7V8.29999H279.9C283.7 8.29999 286.7 9.29999 288.9 11.3C291.1 13.3 292.3 16.1 292.3 19.5C292.3 21.8 291.7 23.8 290.6 25.6C289.5 27.4 287.9 28.6 285.9 29.4C283.9 30.2 281.1 30.6 277.3 30.6H273.1V47.7H267.4V47.7ZM278.9 13.3H273.1V25.6H279.3C281.6 25.6 283.4 25.1 284.6 24C285.8 22.9 286.5 21.4 286.5 19.4C286.4 15.3 283.9 13.3 278.9 13.3Z"
                fill="#008A7B"
            />
            <path
                d="M296.3 8.39999H301.4V25.3C303.5 22.7 306.2 21.4 309.4 21.4C311.1 21.4 312.7 21.8 314.1 22.7C315.5 23.6 316.5 24.8 317.2 26.3C317.9 27.8 318.2 30.1 318.2 33.1V47.9H313V31.9C313 30 312.5 28.5 311.6 27.3C310.7 26.1 309.4 25.6 307.9 25.6C306.8 25.6 305.7 25.9 304.7 26.5C303.7 27.1 302.6 28 301.4 29.4V48H296.3V8.39999Z"
                fill="#008A7B"
            />
            <path
                d="M337.7 21.8C341.6 21.8 344.9 23.1 347.5 25.6C350.1 28.1 351.4 31.3 351.4 35.2C351.4 38.9 350.1 42 347.4 44.5C344.8 47 341.4 48.2 337.4 48.2C333.5 48.2 330.3 46.9 327.6 44.4C325 41.9 323.7 38.8 323.7 35C323.7 31.2 325 28 327.7 25.5C330.4 23 333.7 21.8 337.7 21.8ZM337.4 26.4C334.9 26.4 332.9 27.2 331.3 28.8C329.7 30.4 328.9 32.5 328.9 35C328.9 37.5 329.7 39.5 331.3 41.1C332.9 42.7 335 43.5 337.5 43.5C340 43.5 342.1 42.7 343.7 41.1C345.3 39.5 346.1 37.5 346.1 35C346.1 32.5 345.3 30.5 343.6 28.9C342.1 27.2 340 26.4 337.4 26.4Z"
                fill="#008A7B"
            />
            <path
                d="M362.3 21.8V25.1C364.6 22.6 367.2 21.3 370.1 21.3C371.7 21.3 373.2 21.7 374.6 22.6C376 23.4 377 24.6 377.8 26C378.5 27.5 378.9 29.8 378.9 32.9V47.7H373.8V33C373.8 30.4 373.4 28.5 372.6 27.3C371.8 26.2 370.4 25.6 368.5 25.6C366.1 25.6 364 26.8 362.3 29.2V47.7H357.1V21.8H362.3Z"
                fill="#008A7B"
            />
            <path
                d="M408.1 35.2H389.9C390 37.7 390.9 39.7 392.4 41.1C393.9 42.6 395.9 43.3 398.3 43.3C401.7 43.3 404.8 42.2 407.7 40.1V45.1C406.1 46.2 404.6 46.9 403 47.4C401.5 47.9 399.6 48.1 397.5 48.1C394.6 48.1 392.3 47.5 390.6 46.3C388.8 45.1 387.4 43.5 386.4 41.5C385.3 39.5 384.8 37.2 384.8 34.6C384.8 30.7 385.9 27.4 388.2 25C390.4 22.5 393.3 21.3 396.9 21.3C400.3 21.3 403.1 22.5 405.1 24.9C407.1 27.3 408.2 30.5 408.2 34.6V35.2H408.1ZM390 32.2H403.1C403 30.2 402.4 28.6 401.3 27.5C400.2 26.4 398.7 25.8 396.9 25.8C395.1 25.8 393.6 26.4 392.4 27.5C391.1 28.5 390.4 30.1 390 32.2Z"
                fill="#008A7B"
            />
            <path
                d="M414.1 47.7V8.29999H426.6C430.4 8.29999 433.4 9.29999 435.6 11.3C437.8 13.3 439 16.1 439 19.5C439 21.8 438.4 23.8 437.3 25.6C436.2 27.4 434.6 28.6 432.6 29.4C430.6 30.2 427.8 30.6 424 30.6H419.8V47.7H414.1V47.7ZM425.6 13.3H419.8V25.6H426C428.3 25.6 430.1 25.1 431.3 24C432.5 22.9 433.2 21.4 433.2 19.4C433.1 15.3 430.6 13.3 425.6 13.3Z"
                fill="#008A7B"
            />
            <path
                d="M448.6 21.8V27.7L448.9 27.2C451.4 23.2 453.9 21.2 456.3 21.2C458.2 21.2 460.3 22.2 462.4 24.1L459.7 28.6C457.9 26.9 456.3 26.1 454.7 26.1C453 26.1 451.6 26.9 450.4 28.5C449.2 30.1 448.6 31.9 448.6 34.1V47.7H443.4V21.8H448.6Z"
                fill="#008A7B"
            />
            <path
                d="M468.6 10.9C469.4 10.9 470.2 11.2 470.8 11.8C471.4 12.4 471.7 13.1 471.7 13.9C471.7 14.7 471.4 15.4 470.8 16C470.2 16.6 469.5 16.9 468.6 16.9C467.8 16.9 467.1 16.6 466.5 16C465.9 15.4 465.6 14.7 465.6 13.9C465.6 13.1 465.9 12.4 466.5 11.8C467.1 11.2 467.8 10.9 468.6 10.9ZM466.1 21.8H471.2V47.7H466.1V21.8Z"
                fill="#008A7B"
            />
            <path
                d="M498.1 41.7V46.8C495.5 47.8 493 48.2 490.5 48.2C486.4 48.2 483.2 47 480.8 44.6C478.4 42.2 477.2 39 477.2 34.9C477.2 30.8 478.4 27.5 480.7 25C483 22.5 486.1 21.3 490 21.3C491.3 21.3 492.5 21.4 493.6 21.7C494.7 22 496 22.4 497.5 23.1V28.6C494.9 26.9 492.5 26.1 490.3 26.1C488 26.1 486.1 26.9 484.6 28.5C483.1 30.1 482.4 32.2 482.4 34.7C482.4 37.3 483.2 39.5 484.8 41C486.4 42.6 488.6 43.3 491.3 43.3C493.3 43.4 495.5 42.8 498.1 41.7Z"
                fill="#008A7B"
            />
            <path
                d="M526 35.2H507.8C507.9 37.7 508.8 39.7 510.3 41.1C511.8 42.6 513.8 43.3 516.2 43.3C519.6 43.3 522.7 42.2 525.6 40.1V45.1C524 46.2 522.5 46.9 520.9 47.4C519.3 47.9 517.5 48.1 515.4 48.1C512.5 48.1 510.2 47.5 508.5 46.3C506.7 45.1 505.3 43.5 504.3 41.5C503.2 39.5 502.7 37.2 502.7 34.6C502.7 30.7 503.8 27.4 506.1 25C508.3 22.5 511.2 21.3 514.8 21.3C518.2 21.3 521 22.5 523 24.9C525 27.3 526.1 30.5 526.1 34.6V35.2H526ZM507.9 32.2H521C520.9 30.2 520.3 28.6 519.2 27.5C518.1 26.4 516.6 25.8 514.8 25.8C513 25.8 511.5 26.4 510.3 27.5C509 28.5 508.2 30.1 507.9 32.2Z"
                fill="#008A7B"
            />
            <path
                d="M530.3 45.9V40.4C531.7 41.4 533.2 42.2 534.7 42.9C536.2 43.5 537.5 43.8 538.5 43.8C539.6 43.8 540.5 43.5 541.3 43C542.1 42.5 542.5 41.8 542.5 41.1C542.5 40.3 542.3 39.7 541.8 39.2C541.3 38.7 540.2 38 538.6 37C535.3 35.2 533.1 33.6 532.1 32.3C531.1 31 530.6 29.6 530.6 28C530.6 26 531.4 24.4 532.9 23.1C534.5 21.8 536.4 21.2 538.9 21.2C541.4 21.2 544 21.9 546.7 23.3V28.4C543.7 26.6 541.2 25.6 539.2 25.6C538.2 25.6 537.4 25.8 536.8 26.2C536.2 26.6 535.9 27.2 535.9 27.9C535.9 28.5 536.2 29.1 536.7 29.6C537.3 30.1 538.2 30.8 539.6 31.6L541.4 32.6C545.7 35 547.9 37.7 547.9 40.7C547.9 42.8 547.1 44.6 545.4 45.9C543.7 47.3 541.6 47.9 539 47.9C537.5 47.9 536.1 47.7 534.9 47.4C533.6 47.4 532.1 46.8 530.3 45.9Z"
                fill="#008A7B"
            />
            <path
                d="M554.3 43.6C554.9 43.6 555.5 43.8 555.9 44.3C556.3 44.7 556.6 45.3 556.6 45.9C556.6 46.5 556.4 47 555.9 47.5C555.5 47.9 554.9 48.1 554.3 48.1C553.7 48.1 553.1 47.9 552.7 47.5C552.3 47.1 552 46.6 552 45.9C552 45.3 552.2 44.7 552.7 44.3C553.1 43.8 553.7 43.6 554.3 43.6Z"
                fill="#5BC1A4"
            />
            <path
                d="M573.8 43.7V47.1C572.1 47.7 570.4 48.1 568.8 48.1C566.1 48.1 564 47.3 562.4 45.7C560.8 44.1 560 42 560 39.3C560 36.6 560.8 34.4 562.3 32.8C563.9 31.1 565.9 30.3 568.4 30.3C569.3 30.3 570.1 30.4 570.8 30.6C571.5 30.8 572.4 31.1 573.4 31.5V35.1C571.7 34 570.1 33.5 568.6 33.5C567.1 33.5 565.8 34 564.8 35.1C563.8 36.2 563.3 37.5 563.3 39.2C563.3 41 563.8 42.3 564.9 43.4C566 44.4 567.4 44.9 569.2 44.9C570.6 44.9 572.1 44.5 573.8 43.7Z"
                fill="#5BC1A4"
            />
            <path
                d="M585.9 30.5C588.5 30.5 590.7 31.3 592.4 33C594.1 34.7 595 36.8 595 39.4C595 41.9 594.1 43.9 592.4 45.6C590.6 47.2 588.4 48.1 585.8 48.1C583.2 48.1 581.1 47.3 579.3 45.6C577.6 43.9 576.7 41.9 576.7 39.4C576.7 36.9 577.6 34.8 579.3 33.1C581 31.4 583.2 30.5 585.9 30.5ZM585.7 33.6C584.1 33.6 582.7 34.1 581.7 35.2C580.7 36.3 580.1 37.6 580.1 39.3C580.1 40.9 580.6 42.3 581.7 43.3C582.8 44.3 584.1 44.9 585.8 44.9C587.5 44.9 588.8 44.4 589.9 43.3C591 42.2 591.5 40.9 591.5 39.3C591.5 37.7 590.9 36.3 589.8 35.3C588.7 34.1 587.4 33.6 585.7 33.6Z"
                fill="#5BC1A4"
            />
            <path
                d="M612.8 35.3V47.8H609.4V38.3C609.4 36.4 609.1 35.1 608.6 34.3C608.1 33.5 607.2 33.2 605.9 33.2C605.2 33.2 604.5 33.4 604 33.7C603.4 34 602.7 34.6 602 35.4V47.9H598.6V30.7H602V33C603.7 31.3 605.4 30.4 607.1 30.4C609.3 30.4 611 31.4 612.2 33.5C614 31.4 615.9 30.4 617.9 30.4C619.6 30.4 620.9 31 622 32.2C623.1 33.4 623.6 35.3 623.6 37.8V47.9H620.2V37.7C620.2 36.3 619.9 35.2 619.3 34.4C618.7 33.6 617.9 33.3 616.8 33.3C615.3 33.2 614 33.9 612.8 35.3Z"
                fill="#5BC1A4"
            />
            <path
                d="M10.6 35.3C10.3 34.8 9.70001 34.5 9.10001 34.6L5.7 35.1C5.1 33 4.7 30.8 4.7 28.5C4.7 26.1 5.1 23.8 5.7 21.7L9.10001 22.2C9.60001 22.3 10.3 22 10.6 21.5L17.1 13.4C17.5 13 17.5 12.4 17.1 12L11.5 6.40002C4.8 11.4 0.399994 19.5 0.399994 28.5C0.399994 37.5 5.00001 45.4 11.6 50.4L17.1 44.9C17.5 44.5 17.5 43.9 17.1 43.5L10.6 35.3Z"
                fill="#47BD99"
            />
            <path
                d="M28.2 0.600006C25.4 0.600006 22.6 1.00001 20 1.90001C18.8 2.30001 18.1 3.5 18.5 4.7C18.9 5.9 20.1 6.6 21.3 6.2C23.1 5.6 25 5.3 26.9 5.2V15.7H19.9C19.2 15.7 18.6 16.3 18.6 17C18.6 17.7 19.2 18.3 19.9 18.3H26.9V26.8H14.9C14.2 26.8 13.6 27.4 13.6 28.1C13.6 28.8 14.2 29.4 14.9 29.4H26.9V37.9H19.9C19.2 37.9 18.6 38.5 18.6 39.2C18.6 39.9 19.2 40.5 19.9 40.5H26.9V52C25 51.9 23.1 51.5 21.3 51C20.1 50.6 18.8 51.3 18.5 52.5C18.1 53.7 18.8 55 20 55.3C22.7 56.1 25.4 56.6 28.2 56.6C43.5 56.6 56 44.1 56 28.8C56 13.1 43.6 0.600006 28.2 0.600006ZM49.1 18.1C50.4 20.7 51.2 23.6 51.4 26.6H41.7C41.6 23.6 41.2 20.7 40.6 18.1H49.1ZM47.7 15.6H40C38.9 11.9 37.4 8.70001 35.7 6.30001C40.7 8.00001 44.9 11.3 47.7 15.6ZM29.5 5.80001C32.5 6.60001 35.1 10.5 36.7 15.6H29.5V5.80001ZM29.5 18.1H37.4C38 20.7 38.4 23.6 38.5 26.6H29.4V18.1H29.5ZM29.5 29.2H38.6C38.6 32.2 38.2 35.1 37.6 37.7H29.4L29.5 29.2ZM29.5 51V40.1H37C35.4 45.9 32.7 50.1 29.5 51ZM35.7 50.5C37.6 47.9 39.2 44.3 40.3 40.2H48.3C45.5 45 41.1 48.6 35.7 50.5ZM40.9 37.7C41.4 35 41.7 32.1 41.8 29.2H51.6C51.5 32.2 50.8 35.1 49.7 37.7H40.9Z"
                fill="url(#paint0_linear_2_44)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_2_44"
                    x1="13.673"
                    y1="28.4075"
                    x2="56.0487"
                    y2="28.4075"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#58C1A3" />
                    <stop offset="0.1334" stopColor="#47B299" />
                    <stop offset="0.4872" stopColor="#039A88" />
                    <stop offset="0.7886" stopColor="#008E7E" />
                    <stop offset="1" stopColor="#00897B" />
                </linearGradient>
            </defs>
        </svg>
    );
}
